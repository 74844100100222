import React, { useState } from "react"
import Img from "gatsby-image"
import useGallery from "../hooks/useGallery"
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; 

const Gallery = () => {
    const images = useGallery()
    const [ photoIndex, setPhotoIndex ] = useState(0);
    const [ isOpen, setIsOpen ] = useState(false);
    
    return (
        <div className="gallery">

        {images.map(({ id, fluid }, index) => (
            <button key={id} type="button" onClick={() => {setIsOpen(true); setPhotoIndex(index)}}>
                <Img className="w-100 h-100"  fluid={fluid} />
            </button>
            ))}
        
        

        {isOpen && (
          <Lightbox
            clickOutsideToClose={true}
            mainSrc={images[photoIndex].fluid.src}
            nextSrc={images[(photoIndex + 1) % images.length].fluid.src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].fluid.src}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}



            {/* {images.map(({ id, fluid }) => (
                <Img key={id} fluid={fluid} />
            ))} */}
        </div>
    )
}

export default Gallery