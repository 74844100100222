import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Card} from "react-bootstrap"
import Gallery from "../components/gallery"

class GalleryPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Capernwray Photo Gallery" />
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="page-title my-4">Photo Gallery</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className="d-flex">
                  <h3 className="">Photos</h3>
                  <a href="https://cprnwry.typeform.com/to/ZsCK2pac" className="btn btn-primary ms-auto" target="_blank" rel="noopener noreferrer">Submit a photo</a>
                </Card.Header>
                <Card.Body>
                  <Gallery />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default GalleryPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
